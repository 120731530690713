.feeds-categories-view
{
	background-color: $content-bg !important;

	.categories-list
	{
		@include flex-wrap(wrap);
	}

	.feed-category
	{
		// THIS IS FUCKING AWESOME: http://caniuse.com/#feat=viewport-units
		@include calc(height, "50vw - 15px");
		box-shadow: 0 1px 3px rgba(darken($feed-category-bg, 30%), 0.3);
		border-radius: 2px;
		position: relative;
		display: block;

		.category-image
		{
			width: 100%;
			height: 100%;
			border-radius: 2px;
		}

		.category-bg
		{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background: linear-gradient(rgba(darken($feed-category-bg, 80%), .85), rgba(darken($feed-category-bg, 10%), .3) 40%, rgba($feed-category-bg, .3) 60%, rgba(lighten($feed-category-bg, 10%), .3));
		}

		.category-title
		{
			width: 100%;
			color: $feed-category-color;
			position: absolute;
			top: 2px;
			// left: 8px;
			text-align: center;
			font-weight: 500;
			text-transform: capitalize;

			.icon
			{
				font-size:80px;
				// color:#fff;
				color:rgba(255,255,255,.7);
				margin-left: auto;
				margin-right: auto;
			}
			.selected
			{
				color:rgba(47, 182, 101, 0.6);
			}
		}
	}
}
