$feed-category-bg: $theme-color-3;
$feed-category-color: #FFFFFF;

//Feed entries
$entry-heading-color: $theme-color-3;
$entry-title-color: #FFFFFF;

$entry-excerpt-lines: 5;
$entry-font-size: 14px;

$feed-categories-img-ratio: 1 1;

// Merge maps
$pre-img-ratios: append($pre-img-ratios, $feed-categories-img-ratio);
