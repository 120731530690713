@mixin filter-gradient($start-color, $end-color, $orientation: vertical) {
  $gradient-type: if($orientation == vertical, 0, 1);
  filter: progid:DXImageTransform.Microsoft.gradient(gradientType=#{$gradient-type}, startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}');
}

@mixin headings($from: 1, $to: 6){
  @for $i from $from through $to{
    h#{$i}{
      @content
    }
  }
}

@mixin transition($transitions...)
{
  -webkit-transition: $transitions;
  -moz-transition: $transitions;
  -ms-transition: $transitions;
  -o-transition: $transitions;
  transition: $transitions;
}

// // Placeholder shadow DOM
@mixin _placeholder {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

@mixin box-shadow($val)
{
  -webkit-box-shadow: $val;
  -moz-box-shadow: $val;
  box-shadow: $val;
}

@mixin opacity($opacity)
{
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}
