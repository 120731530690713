/*	Custom styles: ;*/
*
{
	color: $content-color;
}
// ion-view{
// 	// background-image: url(./../img/bg.png)!important;
// 	background-image: url(http://www.texturesforest.com/wp-content/uploads/2015/12/backround-03.jpg)!important;
// }
// .card,ion-item,.item,.list,select,.ng-pristine, .item-select, .ng-empty {
// 	background: rgba(255,255,255,.2);
// }
p
{
	text-align: justify;
}

ion-view {
	background-color: $content-bg !important;
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

.ionic_toast {
	color:#000!important;
	background-color: rgba(0, 0, 0, 0.7) !important;
}

.toast_section {
  color: #000!important;
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.toast_section span {
	color: #fff!important;
}


ion-item.item:hover a {
  background-color: #ddd;
  color:white;
  cursor: pointer;
}

.table-selected-system th {
	font-weight: bold;
}


.wtext {
	color: white;
}

.scroll-div {
	overflow-y: scroll ;
	max-height: 400px;
	min-height:400px;
}

.product-photo-small {
	width: 120px;
	margin: 0 auto;
}

.margin10 {
	margin:10px;
}

.mhover {
	cursor: pointer;
	// background-color:#ddd!important;
}

.button-green, .button-green i {
	background-color: green;
	color:white!important;
}

.subtitle-company {
	font-size: 12px;
	// color:#777;
	color:$theme-color-2;
}

.hi-background {
	background-color: $theme-color-2;
	color: white;
}

.subtitle-mode {
	font-size: 12px;
	// color:#777;
	color:green;
}

.nav-header, .bar,.button-assertive {
  // background-image: url(https://images.freecreatives.com/wp-content/uploads/2016/01/Silver-Brushed-Metal-Texture.jpg)!important;
  // background-image: url(./../img/test.png)!important;
  // background-size: auto 100%;
  // text-shadow: 0px 0px 2px black;
}

.button-red
{
	background: rgb(232, 105, 82);
	color: #fff;
}

.button-warning {
	background: #D7DF01;
	color: #fff;
}
.button-red, .button-red i, .button-warning i {
	background: rgb(232, 105, 82);
	color:#fff!important;
}

.pull-right
{
	float:right!important;
	text-decoration:none;
}
.gray
{
	background-color: #d3d4d4;
}
.air
{
	height:100px;
}
.air200
{
	height:200px;
}

.bar.app-top-bar
{
	background-color: $top-bar-bg;

	.title
	{
		color: $top-bar-color !important;
		*
		{
			color: $top-bar-color !important;
		}
	}

	.button
	{
		color: $top-bar-color !important;
		*
		{
			color: $top-bar-color !important;
		}
	}
}

// Preload images aux styles (ratios)
// Auto generate ratios for the whole app (see: http://stackoverflow.com/a/18120181/1116959)
@each $ratio in $pre-img-ratios {
  .pre-img._#{nth($ratio, 1)}_#{nth($ratio, 2)}
  {
    // Conserve aspect ratio (see: http://stackoverflow.com/a/10441480/1116959)
    width: 100%;
    @include calc(padding-bottom, "#{( nth($ratio, 2) / nth($ratio, 1)) * 100}%");
  }
}

.pre-img
{
  position: relative;
  background-color: $pre-img-bg;

	// If the image is rounded
	&.rounded-image
	{
		border-radius: 50%;
	}

  &.finish-loading
  {
    background-color: transparent;
    border: none;

		img
	  {
			visibility: visible;
			opacity: 1;
	  }
  }

  img
  {
    position: absolute;
		left: 0px;
		top: 0px;
		visibility: hidden;
		opacity: 0;
		@include transition(visibility 0s linear, opacity 0.5s linear);
  }
}

// spinner-on-load
@include keyframes(spin) {
  from {
    @include transform(rotate(0deg));
  }
  to {
    @include transform(rotate(359deg));
  }
}
.spinner-on-load
{
	position: absolute;
	font-size: $spinner-size;
	width: $spinner-size;
	height: $spinner-size;
	line-height: $spinner-size;
  color: $spinner-color;
	@include animation(spin 2s infinite linear);
	@include calc(top, "50% - #{($spinner-size/2)}");
	@include calc(left, "50% - #{($spinner-size/2)}");
}
ion-spinner.spinner-on-load
{
	@include animation(none);
  stroke: $spinner-color;
	width: $spinner-svg-size;
  height: $spinner-svg-size;
	line-height: inherit;
	@include calc(top, "50% - #{($spinner-svg-size/2)}");
	@include calc(left, "50% - #{($spinner-svg-size/2)}");
}

// Multimedia background
.multi-bg-outer
{
  position: relative;
  background-color: rgba(darken($multi-bg, 10%), 0.7);
  height: 100%;
  @include transition(all ease-in-out .3s);

  background-size: cover;
  background-repeat: no-repeat;
  z-index: -2;

  &.finish-loading
  {
    background-color: transparent;
  }

  .bg-overlay
  {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $overlay-bg;
    z-index: -1;
    opacity: 0.6;
  }

  .multi-bg
  {
    display: none;
  }
}

.loading-container .loading
{
	background-color: $loading-background-color;
  *
  {
    color: darken($loading-color, 5);
		font-weight: 500;
  }
}
