.login-view
{
	// Fix tabs animation on iOS
	// Fix initial blink before angular loaded: http://stackoverflow.com/a/14076004/1116959
	
	
	// overflow: auto;
	// position: relative;

	.login-content {
		background:rgba(255,255,255, .5)
	}

	[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak
	{
	  display: none !important;
	}
	.row
	{
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
	}

	.tab-content.ng-hide
	{
		display: none !important;
	}

	.tab-content.ng-hide-add.ng-hide-add-active,
	.tab-content.ng-hide-remove.ng-hide-remove-active
	{
		@include transition(all 0s ease);
	}

	.tabs
	{
		.tab-item
		{
			font-weight: bold;
			text-transform: uppercase;
			background: $auth-tabs-bg;
			color: $auth-tabs-color;
			@include transition(all 0.1s ease);
		}

		.tab-item.active,
		.tab-item.activated
		{
			margin-top: 0;
			margin-bottom: -2px;
			border-width: 0px 0px 4px 0px !important;
			border-color: $auth-tabs-highlight !important;
			border-style: solid;
		}
	}

	.login-container
	{
		margin-bottom: 0px;

		.card-heding
		{
			padding: 0px;
			height: $tabs-height;

			.tabs
			{
				top: 0px;
				padding-top: 0;
				background-position: bottom;
				border-top-width: 0;
				border-bottom-width: 1px;
			}

			.tab-item
			{
				max-width: none;
			}
		}
	}

	/* do not group these rules */
	*::-webkit-input-placeholder {
		color: black;
	}
	*:-moz-placeholder {
		/* FF 4-18 */
		color: black;
	}
	*::-moz-placeholder {
		/* FF 19+ */
		color: black;
	}
	*:-ms-input-placeholder {
		/* IE 10+ */
		color: black;
	}
}


.login-view:before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  z-index: -1;

  display: block;
  background-image: url('./../img/bg1.png');
  background-size:cover;
  width: 100%;
  height: 100%;

  filter: blur(5px);
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
}