[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

// body {
//  -webkit-user-select: auto !important;
// .scroll {
//     -webkit-user-select: inherit;
//   }
// }

.warning {
  margin: 10px 0px;
  padding:12px;
  color: #9F6000;  
  background-color: #FEEFB3;
  strong {
    color: #7F4000;
  }
}

// .nav-bar-block, .bar {
//   background-color: inherit !important;
// }

.scroll-content {
  user-select: auto !important;
}

@media screen and (max-width: 767px) {
    .hidden-xs {
        display:none;
    }
}

// add scrolling bar to the matches of the quoting system module

.scroll-matches {
   
  overflow-y: scroll !important;
  max-height: 280px;
  min-height:280px;
  overflow:scroll !important;
  -webkit-overflow-scrolling:touch !important;

}



#loading-bar .bar {
  background-color: red !important;  
}

.hi-title {
	color: $theme-color-2;
	text-align: center;
	padding:10px;
}

.hi-color {
  color: $theme-color-2 !important;
}

.upload-product-files {
  margin-top: 10px;
}

.hi-error {
	color: #FA5858;
	text-align: center;
	padding:10px;
}

.muted {
	font-size: 12px;
	color: #747474;
}

.product-details {  
  border: 2px solid #0070bc;
  border-radius: 5px;
  margin:8px;
  padding:5px;
  // background-color: #50b0fc;
  // max-width: 350px;
  // background-color: #0070bc;

  // p, h4 {
  //   color: white;
  // }
}

.button-xs {
  padding: 2px 4px 1px;
  min-width: 28px;
  min-height: 30px;
  font-size: 9px;
  line-height: 24px; 
    i{
      font-size: 10px!important;
    }
  }  


