/* System notifications */
.ngn {
    color: #FFF;
    cursor: default;
    display: none;
    font-size: 1.3em;
    left: 0;
    opacity: 1;
    padding: 25px 80px;
    position: fixed;
    right: 0;
    text-align: center;
    user-select: none;
    z-index: 9999;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; }

.ngn-component {
    position: absolute; }

.ngn-top {
    top: 0; }

.ngn-bottom {
    bottom: 0; }

.ngn-dismiss {
    background-color: #333;
    border-radius: 15px;
    box-shadow: inset 2px 2px 7px 2px #000;
    color: #DDD;
    cursor: pointer;
    display: none;
    font-size: 1.25em;
    font-weight: bold;
    height: 30px;
    line-height: 30px;
    opacity: .2;
    position: absolute;
    right: 40px;
    text-shadow: 1px 1px 5px #000;
    top: 25px;
    width: 30px; }

.ngn-sticky .ngn-dismiss {
    display: block; }

.ngn-dismiss:hover {
    background-color: #000; }

.ngn-dismiss:active {
    background-color: #666; }

/* Reduce notification size for mobile */
@media only screen and (max-width : 480px) {
    .ngn {
        font-size: 1em;
        padding: 12px 25px; }

    .ngn-dismiss {
        font-size: 1em;
        height: 20px;
        line-height: 20px;
        right: 5px;
        top: 5px;
        width: 20px; } }

/* Themes - Default - Pure */
.ngn-info {
    background-color: #0e90d2; }

.ngn-error {
    background-color: #dd514c; }

.ngn-success {
    background-color: #5eb95e; }

.ngn-warn {
    background-color: #f37b1d; }

.ngn-grimace {
    background-color: #8058a5; }

/* Themes - Prime */
.ngn-prime.ngn-info {
    background-color: #0033cc; }

.ngn-prime.ngn-error {
    background-color: #ff0000; }

.ngn-prime.ngn-success {
    background-color: #00cc00; }

.ngn-prime.ngn-warn {
    background-color: #ff9900; }

.ngn-prime.ngn-grimace {
    background-color: #660099; }

/* Themes - Pastel */
.ngn-pastel.ngn-info {
    background-color: #7EA7D8; }

.ngn-pastel.ngn-error {
    background-color: #F6989D; }

.ngn-pastel.ngn-success {
    background-color: #82CA9D; }

.ngn-pastel.ngn-warn {
    background-color: #FDC68A; }

.ngn-pastel.ngn-grimace {
    background-color: #A187BE; }

/* Themes - Pitchy */
.ngn-pitchy.ngn-info {
    background-color: #003471; }

.ngn-pitchy.ngn-error {
    background-color: #9E0B0F; }

.ngn-pitchy.ngn-success {
    background-color: #007236; }

.ngn-pitchy.ngn-warn {
    background-color: #A36209; }

.ngn-pitchy.ngn-grimace {
    background-color: #440E62; }
