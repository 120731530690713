/*
To customize the look and feel of Ionic, you can override the variables
in ionic's _variables.scss file.

For example, you might change some of the default colors:
*/
// $light:                           #fff !default;
// $stable:                          #f8f8f8 !default;
$positive:                        $theme-color-1 !default;
$calm:                            #11c1f3 !default;
$balanced:                        #33cd5f !default;
$energized:                       #ffc900 !default;
$assertive:                       $theme-color-2 !default;
$royal:                           #886aea !default;
$dark:                            #444 !default;


// The path for our ionicons font files, relative to the built CSS in www/css
$ionicons-font-path: "../lib/ionic/fonts" !default;
